<template>
  <div class="settingcoupon">
    <x-dialog :proxy="settingDialog">
      <el-form :model="batchForm" :rules="batchFormRule" ref="batchForm">
        <en-table-layout class="enTableLayout" ref="enTableLayout" :tableData="tableData"
          @selection-change="selectChange" :row-key="(row) => row.id">
          <template slot="toolbar">
            <el-form-item label="卡券应用类型">
              <el-select style="width: 100px" v-model="params.card_type" size="small" placeholder="请选择" clearable>
                <el-option v-for="item in mallTypeList" :key="item.value" :label="item.name" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input style="width: 280px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord"
                clearable>
                <el-select v-model="key_word" slot="prepend" style="width: 100px;">
                  <el-option label="卡券名称" value="card_name"></el-option>
                  <el-option label="所属商城" value="shop_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button native-type="submit" @click.stop.prevent="search" size="small" type="primary">
                搜索
              </el-button>
              <el-button @click="addCard" size="small" type="primary">
                添加卡券
              </el-button>
              <el-button @click="handleActivationCheck(1)" size="small" type="primary">
                批量删除
              </el-button>
              <el-button @click="handleActivationCheck(0)" size="small" type="primary">
                批量设置折扣率
              </el-button>
            </el-form-item>
          </template>

          <template slot="table-columns">
            <el-table-column :key="'selection'" :reserve-selection="true" type="selection" width="50" />
            <el-table-column prop="card_name" label="卡券名称" show-overflow-tooltip />
            <el-table-column prop="card_value" label="卡券面值/价位" width="120">
              <template slot-scope="scope">
                {{ scope.row.card_value | unitPrice() }}
              </template>
            </el-table-column>
            <el-table-column prop="shop_name" label="所属商城" show-overflow-tooltip />
            <el-table-column prop="card_type" label="卡券应用类型" width="120">
              <template slot-scope="scope">
                {{ scope.row.card_type | cardTypeFilter }}
              </template>
            </el-table-column>
            <el-table-column prop="discount_rate" label="折扣率（标品）" width="120">
              <template slot-scope="scope">
                {{ scope.row.discount_rate + "%" }}
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="模板折扣率" width="120">
              <template slot-scope="scope">
                <el-form-item :prop="`discount_list.${scope.$index}.template_discount_rate`"
                  :rules="batchFormRule.template_discount_rate">
                  <el-input v-model.trim="scope.row.template_discount_rate"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <div class="operate" style="white-space: nowrap; display: inline-block">
                  <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </el-form>
    </x-dialog>
    <x-dialog :proxy="settingDiscountRate">
      <div style="padding:10px">
        <p>确定设置为当前的折扣率吗？</p>
        <el-form :model="editForm" ref="editForm" :rules="editFormRule" label-width="80px" size="small">
          <el-form-item label="折扣率" prop="template_discount_rate">
            <el-input type="text" maxlength="100" v-model.trim="editForm.template_discount_rate"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </x-dialog>
    <cardcheck ref="cardCheck" :parentData="batchForm.discount_list" @selectCardCheck="selectCardCheck"></cardcheck>
  </div>
</template>

<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { getDiscountCardsList, batchAddCards } from '@/api/settingCustomer';
import cardcheck from './cardCheck';
import { getShopExtAuth } from '@/api/order';
import { getShopLv } from '@/api/shop';
export default {
  name: 'settingcoupon',
  components: {
    XDialog,
    EnTableLayout,
    cardcheck
  },
  filters: {
    cardTypeFilter (card_type) {
      switch (card_type) {
        case 3:
          return '现金储值卡'
        case 0:
          return '双选储值卡'
        case 5:
          return 'AI智能套餐卡'
        case 6:
          return '计次卡'
        default:
          return '宅配卡'
      }
    }
  },
  data () {
    return {
      //选中得勾选项
      selectCarmiTable: [],
      //来源应用数组
      mallTypeList: [
        { name: '全部', value: '' }
      ],
      key_word: 'card_name',
      params: {
        discount_template_id: '',
        keyWord: '',
        mall_type: "",
        page_size: 0,
        page_no: 0
      },
      tableData: [],
      settingDialog: $xDialog.create({
        title: "卡券折扣定制",
        width: "75vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitForm
      }),
      settingDiscountRate: $xDialog.create({
        title: "批量操作",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.settingForm
      }),
      editForm: {//折扣率设置
        template_discount_rate: ''
      },
      editFormRule: {
        template_discount_rate: [
          { required: true, message: '请填写折扣率', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value < 0 || value > 100 || !reg.test(value)) {
                callback(new Error('仅允许输入0~100，且最多到小数点后两位的正数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      batchForm: {//批量添加折扣模板卡券
        discount_template_id: '',//模板id
        discount_list: [{ template_discount_rate: '' }],
      },
      batchFormRule: {
        template_discount_rate: [
          { required: true, message: '请填写折扣率', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value < 0 || value > 100 || !reg.test(value)) {
                callback(new Error('仅允许输入0~100，且最多到小数点后两位的正数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      parentId: '',//模板id
    }
  },
  watch: {
    'batchForm.discount_list': {
      handler (val) {
        console.log(val);
      },
      deep: true
    }
  },
  async created () {
    //判断大平台开关
    const doubledata = await getShopExtAuth();
    const aicard20 = await getShopLv();
    if (aicard20.data.has_cash_mall === 1) {
      this.mallTypeList.push({ name: "现金储值卡", value: 3 })
    }
    if (doubledata.double_choice_mall_open == "OPEN") this.mallTypeList.push({ name: '双选储值卡', value: 0 })
    if (aicard20.data.has_intelligent_card20 == 1) {
      this.mallTypeList.push({ name: 'AI智能套餐卡', value: 5 })
    }
    if (doubledata.counting_card_open == "OPEN") {
      this.mallTypeList.push({ name: '计次卡', value: 6 })
      this.mallTypeList.push({ name: '宅配卡', value: 7 })
    }

  },
  mounted () {
    //初始化弹窗高度必须有数据支撑
    let params = {
      ...this.params
    };
    params[this.key_word] = params.keyWord;
    delete params.keyWord;
    getDiscountCardsList(params).then((res) => {
      this.batchForm.discount_list = res.data.slice(0, 6);
    });
  },
  methods: {
    handleBlur (value) {
      const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
      if (!reg.test(value)) {
        this.$message.error('仅允许输入0~100，且最多到小数点后两位的正数')
      } else if (value > 100) this.$message.error('仅允许输入0~100，且最多到小数点后两位的正数')
    },
    /**批量设置模板折扣率 */
    settingForm () {
      const { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          let arr=[]
          this.selectCarmiTable.map((item) => {
            arr.push(item.id);
          });
          this.batchForm.discount_list.forEach(item => {
            if(arr.includes(item.id)){
              item.template_discount_rate = this.editForm.template_discount_rate
            }
          })
          resolve(true)
        } else {
          reject(false)
        }
      });
      return promise;
    },
    /**批量添加折扣模板卡券 */
    submitForm () {
      const { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.batchForm.validate((valid) => {
        if (valid) {
          batchAddCards(this.batchForm).then(res => {
            this.$message.success('保存成功')
            this.$emit('getList')
            resolve(true)
          })
        } else {
          this.$message.error('请填写模板折扣率')
          reject(false)
        }
      });
      return promise;
    },
    //添加挑选卡券
    addCard () {
      this.$refs.cardCheck.show();
    },
    show (row) {
      this.parentId = row.id;
      this.params = {
        discount_template_id: row.id,
        keyWord: '',
        card_type: "",
        page_size: 0,
        page_no: 0
      }
      this.editForm= {//折扣率设置
        template_discount_rate: ''
      }
      this.batchForm = {//批量添加折扣模板卡券
        discount_template_id: row.id,//模板id
        discount_list: []
      }
      this.editForm= {//折扣率设置
        template_discount_rate: ''
      }
      this.settingDialog.display();
      setTimeout(() => {
        this.getTableList()
        this.$refs.cardCheck.getTableList();
      }, 500)
    },
    /**查询卡券列表 */
    getTableList (page_no) {
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      getDiscountCardsList(params).then((res) => {
        this.batchForm.discount_list = res.data;
        this.tableData = res.data;
      });
    },
    /**挑选的数据 */
    selectCardCheck (data) {
      data.map(item => {
        item.template_id = this.parentId;
        item.card_id = item.id;
        this.batchForm.discount_list.unshift(item)
      })
      //执行搜索更新表格数据
      this.search();
    },

    /**复选框选中事件 */
    selectChange (val) {
      this.selectCarmiTable = val;
    },
    //批量操作
    handleActivationCheck (num) {
      // 批量操作
      let arr = [];
      if (
        this.selectCarmiTable &&
        this.selectCarmiTable.length &&
        this.selectCarmiTable.length > 0
      ) {
        this.selectCarmiTable.map((item) => {
          arr.push(item.id);
        });
      } else {
        this.$message.error("请先勾选数据");
        return;
      }
      if (num === 0) {//批量设置折扣率
        this.settingDiscountRate.display();
      } else {//批量删除
        const datas = this.batchForm.discount_list.filter(res => arr.includes(res.id))
        datas.forEach(row => {
          this.$refs.enTableLayout.$refs.table.toggleRowSelection(row, false);
        });
        this.batchForm.discount_list = this.batchForm.discount_list.filter(res => !arr.includes(res.id))
        this.selectCarmiTable = []
        //执行搜索更新表格数据
        this.search();
      }
    },
    //删除
    handleDel (row) {
      this.batchForm.discount_list = this.batchForm.discount_list.filter(res => res.id !== row.id)
      this.$refs.enTableLayout.$refs.table.toggleRowSelection(row, false);
      //执行搜索更新表格数据
      this.search();
    },
    /**搜索数据过滤 */
    search () {
      let newList = [
        ...this.batchForm.discount_list
      ]
      let params = {
      ...this.params
    };
    params[this.key_word] = params.keyWord;
      delete params.keyWord;
      if (params.card_name) {//过滤卡券名称
        newList=newList.filter(item => {
          if (item.card_name.indexOf(params.card_name)>-1) {
           return item
          }
        })
      } else if(params.shop_name) {//过滤所属商城
        newList=newList.filter(item => {
          if (item.shop_name.indexOf(params.shop_name)>-1) {
           return item
          }
        })
      }

      if (params.card_type) {//过滤卡券应用类型
        newList=newList.filter(item => {
          if (item.card_type==params.card_type) {
           return item
          }
        })
      }
      this.tableData=newList
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .enTableLayout {
  .el-form-item {
    margin-bottom: 0;
  }

  // .el-table__row .el-table__cell {
  //   padding: 30px 0;
  // }

  .el-form-item__error {
    position: relative;
  }
}

/deep/ .el-scrollbar__bar.is-vertical {
  width: 0;
}
</style>
