<template>
  <div class="card-user-manage">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <el-form-item label="更新时间">
          <el-date-picker style="width: 220px" v-model="order_time_range" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleDateChange"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 280px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="模板名称" value="name"></el-option>
              <el-option label="创建人" value="createUserName"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="seach" size="small" type="primary">搜索</el-button>
          <el-button @click="handleAdd" size="small" type="primary">添加</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column label="模板名称" prop="name" fixed="left" show-overflow-tooltip />
        <el-table-column prop="card_num" label="卡型数量" show-overflow-tooltip />
        <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.update_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="创建人" prop="create_user_name" show-overflow-tooltip />
        <el-table-column label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <div class="operate" style="white-space: nowrap; display: inline-block">
              <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="primary" @click="handleSetting(scope.row)">
                卡券折扣设置
              </el-button>
              <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.data_total" />
    </en-table-layout>

    <!-- 添加编辑模板 -->
    <x-dialog :proxy="editDialog">
      <div style="padding:10px">
        <el-form :model="editForm" ref="editForm" :rules="editFormRule" label-width="80px" size="small"
          @submit.native.prevent>
          <el-form-item label="模板名称" prop="name">
            <el-input type="text" maxlength="100" v-model.trim="editForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </x-dialog>
    <settingcoupon ref="settingCoupon" :parentId="parentId" @getList="getList"></settingcoupon>
  </div>
</template>

<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import settingcoupon from "./components/settingcoupon";
import { getDiscountRateList, addDiscountRate, editDiscountRate, delDiscountRate, checkTemplateName } from '@/api/settingCustomer';
export default {
  components: {
    EnTableLayout,
    XDialog,
    settingcoupon
  },
  data () {
    return {
      parentId: '',
      order_time_range: [],//时间控件
      key_word: 'name',
      params: {
        keyWord: '',
        page_size: 20,
        page_no: 1
      },
      editDialog: $xDialog.create({
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitForm
      }),
      editForm: {
        name: ''
      },
      editFormRule: {
        name: [
          { required: true, message: '请填写模板名称', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const obj = { name: value, type: this.editForm.id ? 1 : 0 };
              const res = await checkTemplateName(obj)
              if (res) callback(new Error('模板名称已存在'))
              else callback()
            }, trigger: 'blur'
          }
        ]
      },
      tableData: {},
    };
  },
  created () {
    this.getList();
  },
  methods: {
    /**打开卡券设置弹窗 */
    handleSetting (row) {
      this.parentId = row.id;
      this.$refs.settingCoupon.show(row);
    },
    /**添加折扣模板 */
    handleAdd () {
      this.editForm = {
        name: ''
      }
      this.editDialog.config.title = '添加操作'
      this.editDialog.display();
    },
    /**编辑折扣模板 */
    handleEdit (row) {
      this.editForm = {
        name: row.name,
        id: row.id
      }
      this.editDialog.config.title = '编辑操作'
      this.editDialog.display();
    },
    /**时间change事件 */
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },
    //提交
    submitForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.editForm.id) {
            editDiscountRate(this.editForm).then((res) => {
              this.$message.success('保存成功');
              this.getList();
              resolve(true)
            });
          } else {
            addDiscountRate(this.editForm).then((res) => {
              this.$message.success('保存成功');
              this.getList();
              resolve(true)
            });
          }
        } else {
          reject(false)
        }
      });
      return promise
    },
    /**搜索 */
    seach () {
      this.getList(1);
    },
    /**列表查询 */
    getList (page_no) {
      let params = { ...this.params };
      page_no && (params.page_no = 1);
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      getDiscountRateList(params).then((res) => {
        this.tableData = res;
      });
    },
    //删除
    handleDel (row) {
      this.$confirm('确定要删除当前的模板吗？', '提示', {
        type: 'warning',
      }).then(() => {
        delDiscountRate(row.id).then((res) => {
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.userData {
  margin-top: 5px;

  .user-statistics {
    background-color: #FAFAFA;
    padding: 5px 10px;

    /deep/ p {
      font-size: 18px;
    }

    /deep/ span {
      color: #00dd9b;
      font-size: 18px;
      font-weight: normal;
    }
  }
}

.importok {
  h1 {}
}

.moreUser {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  margin-left: -375px;
  background-color: #fff;
  width: 750px;
  height: 600px;
}

.domnload1 {
  cursor: pointer;
  color: #0079fe;
}

.domnload1:hover {
  color: red;
}

.shadow {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 1000px;
}

.xleft {
  cursor: pointer;
  float: right;
}

.first {
  font-size: 14px;
  padding: 0px 20px 0px 20px;
}

.secend h2 {
  text-align: center;
  float: left;
  width: 50%;
}

.threeone {
  margin: 20px 10px;
  border: 1px solid rgba(233, 233, 233, 1);
  padding: 18px 0px 10px 120px;
}

.threetwo {
  margin: 20px 10px;
  background-color: rgb(255, 235, 204);
  padding: 15px 20px 15px 60px;
}

.btn2 {
  padding-right: 10px;
  text-align: end;
}

.userAdimin {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px;
}

.demo-ruleForm1 {
  z-index: 2000;
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 0;
  width: 300px;
  padding: 20px;
}

/deep/ .el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input__inner {
  font-size: 12px;
}

/deep/ .el-range-input {
  font-size: 12px;
}

/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 168px;
}

.btn33 /deep/ .el-form-item__content {
  margin-left: 0px !important;
}

.posi {
  z-index: 99;
  position: absolute;
  left: 85px;
  top: 100px;
}

.search {
  padding-top: 10px;
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}
</style>
